<template>
	<div>
		<div class="header">
			<div @click="$router.back(0)" class="titlename">{{ titlename }}></div>
			<div class="current">裁判详情</div>
		</div>

		<div class="back">
			<div class="theleftside">
				<div>
					<div class="titles top">
						<img src="../assets/image/title1.png" alt="">
						<p>公示信息</p>
					</div>
					<div class="boxs">
						<div style="width: 100%;height: 50PX;"></div>
						<div class="box-backs">
							<img :src="date.userInfo.avatar" alt="" class="box-logos">

							<div class="information">
								<div class="name">
									<div class="xm-box">
										<img src="../assets/image/user.png" alt="">
										<div class="xm">姓名：</div>
									</div>
									<div class="xm" v-if="realnameLength">{{ date.userInfo.realname }}</div>
									<el-tooltip v-else class="item" effect="dark" :content="date.userInfo.realname" placement="top-start">
										<div class="xm xms">{{ date.userInfo.realname}}</div>
									</el-tooltip>
								</div>
								<div class="name name2">
									<div class="xm-box">
										<img src="../assets/image/gender.png" alt="">
										<div class="xm">性别：</div>
									</div>
									<div class="xm">{{ date.userInfo.sex == 1 ? '男' : (date.userInfo.sex == 2 ? '女' : '') }}
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div style="background: #F8FBFF;border-radius: 22PX;padding: 0 20PX 20PX 20PX;">
					<div class="nav">
						<div @click="getnav(1)" :class="navIndex == 1 ? 'select' : ''">裁判员</div>
						<div @click="getnav(2)" :class="navIndex == 2 ? 'select' : ''">运动员</div>
						<!-- <div @click="getnav(3)" :class="navIndex == 3 ? 'select' : ''">教练员</div> -->
					</div>
					<div class="xian" style="width: auto;"></div>

					<div v-if="navIndex == 1">
						<div class="hd" style="width: auto;" v-if="date.certificateList.length > 0">
							<div class="zuo" @click="getzuo">
								<img src="../assets/image/zuo.png" alt="">
							</div>
							<div class="xx" ref="xxContainer">
								<div class="diyc-box" v-for="(item, index) in this.date.certificateList" :key="index"
									v-if="index >= currentIndex && index < currentIndex + 3">
									<div class="diyc">
										<div class="diyczuo">
											<img src="../assets/image/logo.png" alt="">
											<div class="name">{{ item[0].sportName }}</div>
										</div>
										<div class="diycyou">{{ item[0].referee_level }}</div>
									</div>
									<div class="diycxian"></div>
									<div class="diyctitle" v-if="item[0].certificate_unit.length <= 11">发证单位：{{ item[0].certificate_unit }} </div>
									<div class="diyctitle" v-else>
										<el-tooltip class="item" effect="dark" :content="item[0].certificate_unit" placement="top-start">
											<div>发证单位：{{ item[0].certificate_unit.slice(0, 11) }}...</div>
										</el-tooltip>
									</div>
									<div class="diyctitle">发证日期：{{ item[0].certificate_date | format }}</div>
									<div class="diyctitle">注册所在区：{{ item[0].certificate_area }}</div>
									<div class="diyctitle">注册状态：
										{{ item[0].apply_for_status == 40 ? '已注册' : (item[0].apply_for_status == 60 ?'未年度注册 ':item[0].apply_for_status) }}
									</div>
								</div>
							</div>

							<div class="you" @click="getyou">
								<img src="../assets/image/you.png" alt="">
							</div>
						</div>

						<div class="navt" style="width: auto;">
							<div class="navtxian"></div>
							<div class="navtname">执裁记录</div>
						</div>

						<div class="arbitration-box" v-for="(item, index) in this.date.enforcementList" :key="index">
							<div >
								<div class="arbitration" @click="getarbitration(item[0])">
									<div class="arbitrationname">
										{{ item[0].match_sport }}
									</div>
									<div :class="item[0].arbitration ? 'triangle-down' : 'triangle-right'"></div>
								</div>
								<div class="timeline"  v-if="item[0].arbitration">
									<el-timeline>
										<el-timeline-item :timestamp="res.match_date" color="#0063FF" v-for="(res, indexs) in item" :key="indexs">
											{{ res.match_name }}
										</el-timeline-item>
									</el-timeline>
								</div>
							</div>
						</div>

						<div v-if="this.$verification(this.date.enforcementList)" class="zx-box" style="margin-top: 0;">
							<img class="zw" src="../assets/image/333.png" alt="">
							<div class="zwxx">暂无信息</div>
						</div>

						<div class="navt" style="margin-top: 0;width: auto;">
							<div class="navtxian"></div>
							<div class="navtname">晋升记录</div>
						</div>

						<div class="arbitration-box promotion" v-for="(item, index) in this.date.promotionList"
							:key="index" :style="{ background: item[0].arbitration ? '#fff' : '', width: 'auto' }">
							<div>
								<div class="arbitration" @click="getarbitration(item[0])">
									<div class="arbitrationname">
										{{ item[0].sportsName }}
									</div>
									<div :class="item[0].arbitration ? 'triangle-down' : 'triangle-right'"></div>
								</div>
								<div class="timelines" v-if="item[0].arbitration" v-for="(res, indexs) in item" :key="indexs">
									<div>
										<div class="jtxx-box">
											<div class="jtxxy">
												<div class="jb">
													<img src="../assets/image/111.png" alt="">
													<div style="flex-shrink: 0;">晋升级别：</div>
													<div>{{ res.refereeLevel }}</div>
												</div>
												<div class="rq">
													<img src="../assets/image/222.png" alt="">
													<div style="flex-shrink: 0;">发证日期：</div>
													<div>{{ res.certificateDate | format }}</div>
												</div>
											</div>
											<div class="dz">
												<img src="../assets/image/99.png" alt="" style="margin-right: 15PX;">
												<div style="flex-shrink: 0;">发证单位：</div>
												<div>{{ res.certificateUnit }}</div>
											</div>
										</div>
										<div class="jtxxxian" v-if="indexs != item.length - 1"></div>
										<div class="jtxxkb" v-if="indexs == item.length - 1"></div>
									</div>
								</div>
							</div>
						</div>
						
						<div v-if="this.$verification(this.date.promotionList)" class="zx-box" style="margin-top: 0;">
							<img class="zw" src="../assets/image/333.png" alt="">
							<div class="zwxx">暂无信息</div>
						</div>
					</div>

					<div v-if="navIndex == 2">
						<div v-if="Object.keys(this.date.sportsman).length === 0" class="zx-box">
							<img class="zw" src="../assets/image/333.png" alt="">
							<div class="zwxx">暂无信息</div>
						</div>
						<div class="ydy-box" style="width: auto;" v-else>
							<div class="ydy ydys" v-for="(item, index) in this.date.sportsman.sportsList" :key="index">
								<div class="name-box">
									<div class="ydyxian"></div>
									<div class="name">{{item}}</div>
								</div>
								<div class="ydybut">
									<div class="ydybutname" @click="getentry(item)">参赛记录</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="navIndex == 3" class="zx-box">
						<img class="zw" src="../assets/image/333.png" alt="">
						<div class="zwxx">暂无信息</div>
					</div>
				</div>
			</div>
		</div>


		<el-dialog :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
			<div class="table">
				<el-table :data="tableData" border style="width: 100%;":header-cell-style="{ background: '#0A6DF3' }">
					<el-table-column label="项目" align="center">
						<template slot-scope="scope">
							{{ scope.row.sports }}
						</template>
					</el-table-column>
					<el-table-column prop="matchName" label="赛事名称" align="center">
					</el-table-column>
					<el-table-column label="赛事时间" align="center">
						<template slot-scope="scope">
							{{ scope.row.matchDateStart }} 至 {{ scope.row.matchDateEnd }}
						</template>
					</el-table-column>
					<el-table-column prop="matchAddressStadium" label="地点" align="center">
					</el-table-column>
					<el-table-column prop="gameGroup" label="组别" align="center">
					</el-table-column>
					<el-table-column prop="event" label="小项" align="center">
					</el-table-column>
					<el-table-column prop="games" label="赛次（轮次）" align="center">
					</el-table-column>
					<el-table-column prop="delegation" label="代表队" align="center">
					</el-table-column>
					<el-table-column prop="names" label="姓名" align="center">
					</el-table-column>
					<el-table-column prop="grade" label="成绩" align="center">
					</el-table-column>
					<el-table-column prop="ranking" label="名次" align="center" width="55">
					</el-table-column>
					<el-table-column prop="remarks" label="备注" align="center">
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>

	</div>
</template>

<script>
export default {
	name: '',
	components: {},
	data() {
		return {
			reverse: false,
			date: {
				certificateList: [],
				enforcementList: [],
				userInfo:{},
				sportsman:{}
			},
			info: {
				pageNo: 1,
				pageSize: 12,
				referee_level: '',
				certificate_area: '',
				sports: '',
				refereeName: '',
				notInIds: ''
			},
			refereeList: [],
			titlename: '',
			options2:[],
			options3:[],
			id: '',
			navIndex: 1,
			dialogVisible: false,
			tableData: [],
			currentIndex: 0,
			realnameLength:true
		}
	},
	filters: {
		format(x) {
			if (x != null && x != '') {
				let time = x.split(" ")[0]
				let parts = time.split('-');
				let result = parts[0] + '-' + parts[1];
				return result
			}
		}
	},
	created() {
		this.judgmentLevel()
		this.areaList()
		this.info.sports = this.$route.query.sports
		this.info.notInIds = this.$route.query.id
		setTimeout(() => {
			this.id = this.$route.query.id
		}, 1000)
		this.getUserAllRefereeDeta(this.$route.query.id)
		this.findAllColumn()
	},
	methods: {
		judgmentLevel(){
			this.$api.judgmentLevel().then(res => {
				this.options2 = res.data.data.result
			})
		},
		areaList() {
			this.$api.areaList().then(res => {
				this.options3 = res.data.data.result
			})
		},
		findAllColumn() {
			this.$api.findAllColumn({
				id: this.$route.query.columnId,
				pid: this.$route.query.columnPid
			}).then(res => {
				this.titlename = res.data.data.pid.name
			})
		},
		getUserAllRefereeDeta(e) {
			this.$api.getUserAllRefereeDeta({ userId: e }).then(res => {
				if( res.data.data.result.userInfo.avatar){
					this.$api.getFile({ objectName: res.data.data.result.userInfo.avatar }).then(ess => {
						this.$convert(ess.data.data.result.url).then((fileUrl) => {
							res.data.data.result.userInfo.avatar = fileUrl
							this.$forceUpdate()
						})
					})
				}else{
					res.data.data.result.userInfo.avatar = require('@/assets/image/defaults.png')
					this.$forceUpdate()
				}

				res.data.data.result.certificateList.forEach(item => {
					item.forEach(err => {
						err.realName = this.$decrypt(err.realName  || '')
						err.certificate_unit = this.$decrypt(err.certificate_unit  || '')

						for (let i in this.options2) {
							if (err.referee_level == this.options2[i].itemValue) {
								err.referee_level = this.options2[i].itemText
							}
						}
						for (let i in this.options3) {
							if (err.certificate_area == this.options3[i].itemValue) {
								err.certificate_area = this.options3[i].itemText
							}
						}
					})
				})

				res.data.data.result.enforcementList.forEach(item => {
					item.forEach(res => {
						let match_date = JSON.parse(res.match_date)
						let arr = match_date.map(err => { return err.startDate + ' 至 ' + err.endDate })
						res.match_date = arr.join("，")
						res.arbitration = true
					})
				})

				res.data.data.result.promotionList.forEach(item => {
					item.arbitration = true
					item.forEach(res => {
						res.certificateUnit = this.$decrypt(res.certificateUnit  || '')
						res.arbitration = true
					})
				})
				res.data.data.result.userInfo.realname = this.$decrypt(res.data.data.result.userInfo.realname  || '')
				if( res.data.data.result.userInfo.realname.length > 10){
					this.realnameLength = false
				}else{
					this.realnameLength = true
				}
				this.date = res.data.data.result
			})
		},
		query(item) {
			this.info.sports = item.sports
			setTimeout(() => {
				this.id = item.refereeId
			}, 1000)
			this.info.notInIds = item.refereeId
			this.getUserAllRefereeDeta(item.refereeId)
			window.scrollTo(0, 0)
		},
		getnav(e) {
			this.navIndex = e
		},
		getarbitration(e) {
			console.log(e)
			e.arbitration = !e.arbitration
		},
		getentry(item) {
			this.dialogVisible = true
			let data = JSON.parse(JSON.stringify(this.date.sportsman))
			data.sports = item
			this.$api.getSportsmanScore(data).then(res => {
				res.data.data.result.forEach(item => {
					item.names = this.$decrypt(item.names || '')
					item.matchAddressStadium = item.addresses.map(items => {
						return items.matchAddressStadium
					}).join('、')
				})
				this.tableData = res.data.data.result
			})
		},
		handleClose() {
			this.dialogVisible = false
		},
		getzuo() {
			if (this.currentIndex > 0) {
				this.currentIndex -= 3;
			}
		},
		getyou() {
			if (this.currentIndex + 3 < this.date.certificateList.length) {
				this.currentIndex += 3;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	width: 1200PX;
	margin: 34PX auto 0;
	font-size: 18PX;
	font-weight: 500;
	color: #333333;
	cursor: pointer;
}

.current {
	color: #0066FF;
}

.back {
	width: 1200PX;
	margin: 0 auto;
	display: flex;
	margin-bottom: 100PX;
}

.theleftside {}

.title {
	width: 876PX;
	height: 57PX;
	background: #D8E7FF;
	border-radius: 22PX 22PX 0PX 0PX;
	display: flex;
	align-items: center;
}

.top {
	margin-top: 39PX;
}

.title img {
	width: 31.4PX;
	height: 38.1PX;
	margin-left: 68PX;
}

.title p {
	font-size: 25PX;
	font-weight: 500;
	color: #0063FF;
	line-height: 36PX;
	margin-left: 33PX;
}

.box {
	width: 876PX;
	// height: 473PX;
	height: 415PX;
	background: #F8FBFF;
	border-bottom-left-radius: 22PX;
	border-bottom-right-radius: 22PX;
}

.box-back {
	width: 685PX;
	height: 310PX;
	background: url('../assets/image/coach.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin-left: 74PX;
	display: flex;
}

.box-logo {
	width: 158PX;
	height: 219PX;
	border-radius: 18PX;
	margin-top: 48PX;
	margin-left: 75PX;
}

.box-title {
	margin-left: 66PX;
	margin-top: 45PX;

	.name {
		display: flex;
		width: 250PX;
		border-bottom: 1PX solid rgba(110, 110, 110, 0.48);
		height: 40PX;
		display: flex;
		justify-content: space-between;
		margin-top: 57PX;

		img {
			width: 18.2PX;
			height: 20.3PX;
			margin-right: 24PX;
		}

		.xm-box {
			display: flex;

			.xm {
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 18PX;
				color: #000000;
				margin-right: 15PX;
			}
		}

		.xm {
			font-family: SourceHanSansCN;
			font-weight: 500;
			font-size: 18PX;
			color: #000000;
			margin-right: 15PX;
		}
	}

	.name2 {
		margin-top: 25PX;
	}
}

.box-name {
	font-size: 18PX;
	font-weight: 400;
	color: #000;
	margin-bottom: 9PX;
	margin-top: 50PX;
}

.ststus {
	font-size: 21PX;
	font-weight: 400;
	color: #0063FF;
	margin-top: 33PX;
	margin-left: 130PX;
}

.none {
	font-size: 22PX;
	font-weight: 400;
	color: #ACACAC;
	margin-left: 93PX;
	padding: 36PX;
}

.timeline {
	margin-left: 43PX;
	// padding: 69PX;
}

::v-deep .el-timeline-item__tail {
	border-left: 2PX solid #0063FF !important;
}

::v-deep .el-timeline-item__content {
	font-size: 20PX;
	font-weight: 400;
	color: #000000;
}

::v-deep .el-timeline-item__timestamp {
	font-size: 15PX;
	font-weight: 400;
	color: #ACACAC;
}

.therightside {
	margin-top: 39PX;
	margin-left: 39PX;
}

.berelatedto-box {
	width: 366PX;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-left: 29PX;
	padding-top: 49PX;
}

.berelatedto {
	margin-bottom: 27PX;
	margin-right: 25PX;
}

.berelatedto:nth-child(2n) {
	margin-right: 0;
}

.berelatedto-logo {
	width: 144PX;
	height: 169PX;
	border-radius: 18PX;
}

.berelatedto-name {
	font-size: 22PX;
	font-weight: 400;
	color: #000000;
	margin-top: 13PX;
	width: 144PX;
	text-align: center;
}

.titlename:hover {
	color: #0066FF;
}

::v-deep .el-timeline-item__timestamp {
	font-size: 20PX;
	line-height: 23PX;
}


.nav {
	display: flex;
	margin-top: 27PX;

	div {
		width: 180PX;
		height: 50PX;
		background: #E8F2FF;
		border-radius: 20PX 20PX 0PX 0PX;
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 23PX;
		color: #437DCA;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16PX;
		cursor: pointer;
	}
}

.xian {
	width: 836PX;
	height: 2PX;
	background: #85B0E9;
	border-radius: 1PX;
}

.select {
	background: linear-gradient(90deg, #6FB2FF, #095FFF) !important;
	box-shadow: 0PX 7PX 16PX 0PX rgba(50, 129, 255, 0.51);
	border-radius: 20PX 20PX 0PX 0PX;
	color: #ffffff !important;
}

.hd {
	margin-top: 65PX;
	display: flex;
	width: 836PX;

	.zuo {
		width: 44PX;
		height: 44PX;
		margin-right: 13PX;
		margin-top: 51PX;
		cursor: pointer;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.you {
		width: 44PX;
		height: 44PX;
		margin-left: 13PX;
		margin-top: 51PX;
		cursor: pointer;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.xx {
		display: flex;
		width: 1041PX;
		overflow: hidden;

		.diyc-box {
			width: 308PX;
			height: 204PX;
			background: url('../assets/image/88.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin-right: 39PX;

			.diyc {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.diyczuo {
					display: flex;
					padding-top: 28PX;
					padding-left: 22PX;

					img {
						width: 39PX;
						height: 37PX;
					}

					.name {
						font-family: SourceHanSansCN;
						font-weight: bold;
						font-size: 20PX;
						color: #1469FF;
						margin-left: 17PX;
						display: flex;
						align-items: center;
					}
				}

				.diycyou {
					width: 70PX;
					height: 26PX;
					background: url('../assets/image/77.png');
					background-repeat: no-repeat;
					background-size: 100% 100%;
					font-family: SourceHanSansCN;
					font-weight: bold;
					font-size: 16PX;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					top: -5PX;
					right: 38PX;
				}
			}

			.diycxian {
				width: 256PX;
				height: 1PX;
				background: #7FB5FD;
				border-radius: 0PX;
				margin: 15PX 0 19PX 30PX;
			}
			
			.diyctitle {
				// font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 15PX;
				color: #5E5E5E;
				margin-bottom: 4PX;
				margin-left: 29PX;
			}
		}
	}
}

.navt {
	width: 836PX;
	height: 58PX;
	background: #FFFFFF;
	box-shadow: 5PX 14PX 18PX 3PX rgba(170, 202, 237, 0.16);
	border-radius: 19PX;
	margin: 67PX 0 34PX 0;
	display: flex;
	align-items: center;

	.navtxian {
		width: 9PX;
		height: 23PX;
		background: #2A7AFF;
		border-radius: 5PX;
		margin-left: 38PX;
	}

	.navtname {
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 26PX;
		color: #2A7AFF;
		margin-left: 21PX;
	}
}

.arbitration-box {
	width: 836PX;

	.arbitration {
		min-width: 115PX;
		height: 38PX;
		background: linear-gradient(150deg, #0D63FF, #3280FF);
		border-radius: 19PX 19PX 19PX 0PX;
		display: inline-block;
		cursor: pointer;
		line-height: 38PX;
		margin-bottom: 29PX;
		padding: 0 19PX;
	}

	.arbitrationname {
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 20PX;
		color: #FFFFFF;
		margin: 0 19PX;
		display: inline-block;
	}

	.triangle-down {
		width: 0;
		height: 0;
		border-left: 4PX solid transparent;
		/* 左边框为透明，宽度为4PX */
		border-right: 4PX solid transparent;
		/* 右边框为透明，宽度为4PX */
		border-top: 7PX solid #FFFFFF;
		/* 上边框为白色，高度为7PX */
		display: inline-block;
		position: relative;
		top: -2PX;
	}

	.triangle-right {
		width: 0;
		height: 0;
		border-top: 4PX solid transparent;
		/* 上边框为透明，高度为4PX */
		border-bottom: 4PX solid transparent;
		/* 下边框为透明，高度为4PX */
		border-left: 7PX solid #FFFFFF;
		/* 左边框为白色，宽度为7PX */
		display: inline-block;
		position: relative;
		top: -2PX;
	}

	// .timeline {
	// 	margin-top: 20PX;
	// 	margin-left: 45PX;

	// 	::v-deep .el-timeline-item__content {
	// 		font-family: SourceHanSansCN;
	// 		font-weight: 500;
	// 		font-size: 19PX;
	// 		color: #000000;
	// 	}

	// 	::v-deep .el-timeline-item__timestamp {
	// 		font-family: SourceHanSansCN;
	// 		font-weight: 500;
	// 		font-size: 15PX;
	// 		color: #9B9B9B;
	// 	}

	// 	::v-deep .el-timeline-item__tail {
	// 		border-left: 2PX solid #0063FF !important;
	// 	}

	// 	::v-deep .el-timeline-item {
	// 		padding-bottom: 40PX !important;
	// 	}
	// }
}

.promotion {
	border-radius: 19PX;

	.timelines {
		.jtxx-box {
			margin-left: 35PX;

			.jtxxy {
				display: flex;
				align-items: center;
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 19PX;
				color: #000000;

				.jb {
					display: flex;

					img {
						width: 20.2PX;
						height: 26.3PX;
					}

					div:nth-child(3) {
						color: #2D7CFF;
					}
				}

				.rq {
					display: flex;
					position: absolute;
					margin-left: 282PX;

					img {
						width: 22.1PX;
						height: 20.5PX;
					}
				}
			}

			.dz {
				display: flex;
				align-items: center;
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 19PX;
				color: #000000;
				margin-top: 33PX;

				img {
					width: 26PX;
					height: 20PX;
				}

			}

			img {
				margin-right: 20PX;
			}
		}

		.jtxxxian {
			height: 1PX;
			background: #D3D3D3;
			border-radius: 0PX;
			margin: 30PX 35PX;
		}

		.jtxxkb {
			padding-bottom: 35PX;
			margin-bottom: 35PX;
		}
	}
}

.ydy-box {
	margin: 47PX 0 0 0;
	width: 836PX;
	display: flex;
	flex-wrap: wrap;


	.ydy {
		width: 257PX;
		height: 152PX;
		background: url('../assets/image/555.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-right: 32PX;
		margin-bottom: 30PX;
		.name-box {
			display: flex;
			align-items: center;
			margin-left: 26PX;
			padding-top: 18PX;

			.ydyxian {
				width: 6PX;
				height: 19PX;
				background: #417BC8;
				border-radius: 3PX;
			}

			.name {
				font-family: SourceHanSansCN;
				font-weight: bold;
				font-size: 26PX;
				color: #408BFF;
				line-height: 36PX;
				background: linear-gradient(150deg, #336EBC 0.146484375%, #81B7FF 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				margin-left: 21PX;
			}
		}

		.ydybut {
			width: 139PX;
			height: 36PX;
			background: url('../assets/image/444.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			display: flex;
			align-items: center;
			margin-top: 40PX;
			margin-left: 77PX;
			cursor: pointer;

			.ydybutname {
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 20PX;
				color: #629AE4;
				margin-left: 40PX;
			}
		}
	}

	.ydy:nth-child(3n) {
		margin-right: 0;
	}
}

.table ::v-deep .el-table thead {
	color: #fff;
	font-size: 16PX;
	font-weight: 500;
}

.table ::v-deep .el-table__header-wrapper {
	border-top-left-radius: 10PX;
	border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
	border-top-left-radius: 10PX;
	border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
	border-bottom: 1PX solid #333333;
	border-right: 1PX solid #333333;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
	border-color: #333333;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
	background-color: #333333;
}

.zx-box {
	margin-top: 202PX;
	display: flex;
	flex-direction: column;
	align-items: center;

	.zw {
		width: 257PX;
		height: 162PX;
	}

	.zwxx {
		font-family: SourceHanSansCN;
		font-weight: 500;
		font-size: 22PX;
		color: #989898;
		margin-top: 27PX;
		margin-bottom: 40PX;
	}
}

.box-backs {
	width: 963PX;
	height: 253PX;
	background: url('../assets/image/coachs.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin-left: 74PX;
	display: flex;
}

.titles {
	width: 1200PX;
	height: 57PX;
	background: #D8E7FF;
	border-radius: 22PX 22PX 0PX 0PX;
	display: flex;
	align-items: center;
}

.titles img {
	width: 31.4PX;
	height: 38.1PX;
	margin-left: 68PX;
}

.titles p {
	font-size: 25PX;
	font-weight: 500;
	color: #0063FF;
	line-height: 36PX;
	margin-left: 33PX;
}

.boxs {
	width: 1200PX;
	// height: 473PX;
	height: 415PX;
	background: #F8FBFF;
	border-bottom-left-radius: 22PX;
	border-bottom-right-radius: 22PX;
}

.box-logos {
	width: 158PX;
	height: 219PX;
	border-radius: 18PX;
	margin-top: 23PX;
	margin-left: 25PX;
}

.information {
	margin-left: 28PX;

	::v-deep .input-with-select {
		input {
			background: #e4f0ff;
			color: #e4f0ff;
			border: none !important;
		}

		.el-input-group__append {
			background: #3b88ff;
			color: #fff;
			border: none !important;
		}
	}

	.name {
		display: flex;
		width: 300PX;
		border-bottom: 1PX solid rgba(110, 110, 110, 0.48);
		height: 40PX;
		display: flex;
		justify-content: space-between;
		margin-top: 79PX;

		img {
			width: 18.2PX;
			height: 20.3PX;
			margin-right: 24PX;
		}

		.xm-box {
			display: flex;

			.xm {
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 18PX;
				color: #000000;
				margin-right: 15PX;
				flex-shrink: 0;
			}
		}

		.xm {
			font-family: SourceHanSansCN;
			font-weight: 500;
			font-size: 18PX;
			color: #000000;
			margin-right: 15PX;
		}

		.xms{
			width: 60%;
			overflow: hidden;
			height: 25PX;
			text-align: right;
			white-space: nowrap; /* 确保文本在一行内显示 */
			overflow: hidden; /* 隐藏超出容器的文本 */
			text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
		}
		.item{
			color:#437DCA;
		}
	}

	.name2 {
		margin-top: 25PX;
	}

	.buton {
		width: 357PX;
		height: 54PX;
		background: url('../assets/image/66.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-top: 27PX;
		color: rgba(29, 79, 206, 1);
		font-size: 21PX;
		font-family: SourceHanSansCN-Bold;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.ydys:nth-child(4n) {
	margin-right: 0 !important;
}

.ydys:nth-child(3n) {
	margin-right: 32PX !important;
}

::v-deep .el-dialog__body{
	max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
}
</style>
